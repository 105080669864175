<template>
<!-- eslint-disable max-len -->
  <div class="row ">
    <!-- Events list -->
    <div class="col-lg-8 col-xl-9 col-sm-12 px-2 event-list">

      <div class="row mb-4 mr-2 ml-2 d-flex justify-content-between">
        <div class="">
          <button class="btn d-flex align-items-center wham-primary-color text-white no-text-decoration font-weight-bold rounded-pill py-2 px-3 pointer-on-hover"
            @click="goToEvent(0)"
          >
            <span class="material-icons-outlined md-18 mr-2">
            calendar_today
            </span>
            Add {{ isExperienceView ? 'Experience' : 'Event' }}
          </button>
        </div>
        <div class="">
          <button class="btn d-flex align-items-center bg-light font-weight-bold text-primary no-text-decoration font-weight-bold rounded-pill py-2 px-3 pointer-on-hover"
            @click="getEvents(1)"
            :disabled="loading"
          >
            <template v-if="loading">
              <div class="spinner-border  spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </template>
            <template v-else>
              <span class="material-icons-outlined md-18 mr-2">
              refresh
              </span>
              Refresh
            </template>
          </button>
        </div>
      </div>

      <template v-for="(event, index) in events">
        <div class="row card event-row mr-2 ml-2"
          :key="event.id"
          :class="{ 'mb-4': index !== (events.length - 1)}">
          <div class="col">
            <div class="row event-main-content pt-2 pb-1 px-3">
              <div class="col-md-3 col-sm-12 w-100 pb-3 pt-1">
                <img :src="event && event.event_image ? event.event_image.image : ''" class="img-fluid rounded"/>
              </div>
              <div class="col-md-8">
                <div class="row pb-1">
                  <div class="col-md-8">
                    <span class="event-title">{{ event.title }}</span>
                  </div>
                  <div class="col-md-2 d-flex align-items-center">
                    <span
                      class="event-status badge badge-pill badge-success pt-1 pb-1">
                      Published
                    </span>
                  </div>
                  <div class="col-md-2 mt-1 d-flex align-items-center event-edit">
                    <span
                      @click="goToEvent(event.uuid)"
                      class="badge badge-pill px-3 py-2">
                      Edit
                    </span>
                  </div>
                </div>
                <div class="row pb-2 event-tags">
                  <template v-for="tag in event.tags">
                    <div class="col d-flex align-items-center"
                      :key="tag.id">
                      <span class="material-icons-outlined">
                      theaters
                      </span>
                      {{ tag.name }}
                    </div>
                  </template>
                </div>
                <div class="row">
                  <div class="col event-description mb-3">
                    <p>
                      {{ event.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row event-extra-content pt-2 pb-1 px-3">
              <div class="col" :class="eventExtraClasses.column">
                <span class="material-icons-outlined" :class="eventExtraClasses.icon">
                event
                </span>
                <span class="pt-2">
                  {{ getEventDisplayTime(event) }}
                </span>
              </div>
              <div class="col" :class="eventExtraClasses.column">
                <span class="material-icons-outlined" :class="eventExtraClasses.icon">
                place
                </span>
                {{ event && event.venue ? event.venue.name : '' }}
              </div>
              <div class="col" :class="eventExtraClasses.column">
                <span class="material-icons-outlined" :class="eventExtraClasses.icon.concat([isEventSoldOut(event) ? 'bg-danger text-light' : 'text-dark'])">
                sell
                </span>
                {{ getEventTicketBalance(event) }}
              </div>
              <div
                class="col"
                :class="[...eventExtraClasses.column, ...getEventOrderCount(event) ? ['pointer-on-hover', 'text-primary-on-hover'] : []]"
              >
                <span class="material-icons-outlined"
                :class="[...eventExtraClasses.icon, ...getEventOrderCount(event) ? ['pointer-on-hover', 'text-primary-on-hover'] : ['text-white', 'bg-danger']]"
                  @click="goToOrders(event)"
                >
                attach_money
                </span>
                Orders
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="row py-3">
        <div class="col d-flex justify-content-center align-items-center">
          <template v-if="loading">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </template>
          <template v-else-if="hasMore">
            <a class="page-end"></a>
          </template>
          <template v-else>
            <span class="">End</span>
          </template>
        </div>
      </div>
    </div>

    <!-- Calendar -->
    <div class="col-lg-4 col-xl-3 event-calendar px-2">
      <multiple-dates-picker
        :dates="eventDates"
      />
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import { mapState } from 'vuex';
// import debounce from 'lodash/debounce';
import humanizeDuration from 'humanize-duration';

import MultipleDatesPicker from '@/components/MultipleDatesPicker.vue';

export default {
  components: { MultipleDatesPicker },
  data() {
    return {
      eventExtraClasses: {
        column: ['d-flex', 'flex-column', 'align-items-center', 'justify-content-between'],
        icon: ['pt-1', 'pb-2', 'd-flex', 'justify-content-center', 'align-items-center'],
      },
      page: 1,
      pageSize: 10,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getEvents();
      next();
    });
  },

  beforeRouteUpdate(to, from, next) {
    this.getEvents();
    next();
  },

  computed: {
    ...mapState('events', {
      events: 'events',
      loading: 'isLoading',
      hasMore: 'hasMoreContent',
    }),
    eventDates() {
      const dates = [];
      this.events.forEach((event) => {
        dates.push({
          date: this.getEventStartTime(event),
          id: event.uuid,
        });
      });
      return dates;
    },

    isExperienceView() {
      return this.$route.meta.event_type === 'experience';
    },

    // pageContentOverflow() {
    //   const routeName = this.$route.name;
    //   return (
    //     routeName === 'DashboardEvents'
    //     || routeName === 'DashboardExperiences'
    //   ) ? {
    //       'overflow-y': 'auto',
    //       height: '83vh',
    //     }
    //     : {
    //       'overflow-y': 'hidden',
    //     };
    // },
  },

  methods: {
    getEvents(page, pageSize) {
      let action = 'events/getEvents';
      if (this.isExperienceView) {
        action = 'events/getExperiences';
      }
      let loadPage = page;
      let loadPageSize = pageSize;
      if (!loadPage) {
        loadPage = this.page;
      }
      if (!loadPageSize) {
        loadPageSize = this.pageSize;
      }
      return this.$store.dispatch(
        action,
        {
          page: loadPage,
          pageSize: loadPageSize,
        },
      );
    },

    goToEvent(eventId) {
      let routeName = 'DashboardEventDetail';
      if (this.isExperienceView) {
        routeName = 'DashboardExperienceDetail';
      }

      if (eventId) {
        let action = 'events/getEvent';
        if (this.isExperienceView) {
          action = 'events/getExperience';
        }

        this.$store.dispatch(action, eventId)
          .then((response) => {
            if (response.success) {
              this.$router.push({
                name: routeName,
                params: {
                  eventId,
                },
              });
            }
            return response;
          });
      } else {
        this.$router.push({
          name: routeName,
          params: {
            eventId: 0,
          },
        });
      }
    },

    goToOrders(event) {
      if (this.getEventOrderCount(event)) {
        this.$router.push({
          name: 'DashboardOrders',
          query: {
            eventId: event.uuid,
            eventTitle: event.title,
          },
        });
      }
    },

    getEventTicketData(event) {
      return event.ticket_data
        ? event.ticket_data : {
          is_free: false,
          price_currency: '',
          price_start: '',
          price_end: '',
          qty_available: 0,
          qty_balance: 0,
        };
    },

    isEventSoldOut(event) {
      const ticketData = this.getEventTicketData(event);
      return ticketData.qty_balance === 0;
    },

    getEventOrderCount(event) {
      const ticketData = this.getEventTicketData(event);
      return ticketData.orders;
    },

    getEventTicketBalance(event) {
      const ticketData = this.getEventTicketData(event);
      return ticketData.qty_balance > 0 ? `${ticketData.qty_balance} pcs left` : 'Sold out';
    },

    getEventTicketPriceStart(event) {
      const ticketData = this.getEventTicketData(event);
      return ticketData.price_currency ? Number(ticketData.price_start).toFixed(0) : 'N/A';
    },

    getEventTicketPriceCurrency(event) {
      const ticketData = this.getEventTicketData(event);
      return ticketData.price_currency || '';
    },

    getEventStartTime(event) {
      let startTime = '';
      if (event && event.dates && event.dates.length > 0) {
        startTime = event.dates[0].start_time;
      }
      return startTime;
    },

    getEventDisplayTime(event) {
      if (event.duration) {
        return humanizeDuration(event.duration);
      }
      return 'TBD';
    },

    // isScrolledIntoView(el) {
    //   const rect = el.getBoundingClientRect();
    //   const elemTop = rect.top;
    //   const elemBottom = rect.bottom;

    //   const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    //   return isVisible;
    // },

    // onScroll() {
    //   console.debug('onscroll');
    //   const debounced = debounce(this.scroll, 1000);
    //   debounced();
    // },

    // scroll() {
    //   console.debug('on scrolled');
    //   const scrolledTo = document.querySelector('.page-end');

    //   if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
    //     console.debug('page end');
    //     if (!this.loading) {
    //       const lastElement = this.page * this.pageSize;
    //       const page = this.page + 1;
    //       this.getEvents(page)
    //         .then((response) => {
    //           if (response.success) {
    //             this.page += 1;
    //           }
    //           const el = this.$el.querySelector(`.event-row:nth-child(${lastElement})`);
    //           console.debug(`scrolling to view ${el}`);
    //           if (el) {
    //             el.scrollIntoView({ behaviour: 'smooth' });
    //           }
    //         });
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss">
$font-size-base: 12px;
$row-shadow: 1px 5px 5px 0 #ccc;

.event-list {
  font-size: $font-size-base;
  // overflow-y: auto;
  padding-top: 24px;

  .event-row {
    box-shadow: $row-shadow;
  }

  .event-title {
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event-description {
    height: 100%;
    max-height: 25vh;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event-main-content {
    img {
      min-height: 100%;
      min-width: 100%;
    }

    .event-edit {
      font-size: 18px;

      .badge {
        background-color: $wham-icon-bg-color;
      }
    }

    .event-edit .badge:hover {
      cursor: pointer;
      background-color: $wham-primary-color;
      color: $wham-sec-light-color;
    }
  }

  .event-tags {
    font-size: 12px;
    color: $wham-bold-color;

    .material-icons-outlined {
      font-size: 18px;
    }
  }

  .event-extra-content {
    border-top: 1.5px solid rgb(161, 160, 160);

    .material-icons-outlined {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-color: $wham-icon-bg-color;
    }
  }
}

.event-calendar {
  .vc-highlight {
    // Override color for date highlights
    background-color: $wham-primary-color !important;
  }
}

</style>
