<template>
  <v-date-picker
    is-expanded
    mode="date"
    :value="days"
    :attributes="attributes"
    :color="'red'"
    @dayclick="onDayClick"
  />
</template>

<script>
export default {
  props: ['dates', 'selectable'],
  data() {
    return {
      days: this.dates,
    };
  },
  watch: {
    dates(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.days = newVal;
      }
    },
  },
  computed: {
    mDates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.mDates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  methods: {
    onDayClick(day) {
      if (!this.selectable) {
        return;
      }

      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }
    },
  },
};
</script>

<style>
.vc-container {
  border: none;
}
</style>
